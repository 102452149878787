import { lazy } from 'react';
import { env } from '../../shared/functional/global-import';

//*************Configure all routes of the application that contain authenticate template **************

const AuthenticateTemplateRoutesConfing = [
 
  {
    exact: true,
    private: true,
    moduleName: "Dashboard",
    screenName: "dashboard", 
    path: `${env.PUBLIC_URL}/dashboard`,
    component: lazy(() => import('../../pages/dashboard')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName: "Lesson Library",
    screenName: "lesson-library", 
    path: `${env.PUBLIC_URL}/lesson-library`,
    component: lazy(() => import('../../pages/lesson-library')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName: "Lesson Plan",
    screenName: "lesson-plan", 
    path: `${env.PUBLIC_URL}/lesson-plan`,
    component: lazy(() => import('../../pages/lesson-plan')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName: "Lesson Plan",
    screenName: "lesson-plan-creation", 
    path: `${env.PUBLIC_URL}/lesson-plan-creation`,
    component: lazy(() => import('../../pages/lesson-plan-creation')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName: "Alee Library",
    screenName: "book-flip", 
    path: `${env.PUBLIC_URL}/book-flip`,
    component: lazy(() => import('../../pages/book-flip')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName: "Teacher LessonOnlyPlan",
    screenName: "teacher-lessonOnlyPlan", 
    path: `${env.PUBLIC_URL}/teacher-lessonOnlyPlan`,
    component: lazy(() => import('../../pages/teacher-LessonOnlyPlan')),
    role: ["Admin", "SubAdmin",],
  },
  
  
  {
    exact: true,
    private: true,
    moduleName: "Upload Book",
    screenName: "upload-books", 
    path: `${env.PUBLIC_URL}/upload-books`,
    component: lazy(() => import('../../pages/upload-pdf')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName: "Upload Book",
    screenName: "upload-books",
    path: `${env.PUBLIC_URL}/upload-books/:id`,
    component: lazy(() => import('../../pages/upload-pdf')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName: "Alee Library",
    screenName: "my-books",
    path: `${env.PUBLIC_URL}/my-books`,
    component: lazy(() => import('../../pages/my-books')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName: "Resource",
    screenName: "resources",
    path: `${env.PUBLIC_URL}/resources`,
    component: lazy(() => import('../../pages/resources')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName: "Book Summary",
    screenName: "book-summary",
    path: `${env.PUBLIC_URL}/book-summary`,
    component: lazy(() => import('../../pages/book-summary')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName: "Sub Admin",
    screenName: "sub-admin",
    path: `${env.PUBLIC_URL}/sub-admin`,
    component: lazy(() => import('../../pages/sub-admin')),
    role: ["Admin", "SubAdmin",],
  },
  
  {
    exact: true,
    private: true,
    moduleName: "School",
    screenName: "add-schools",
    path: `${env.PUBLIC_URL}/add-schools`,
    component: lazy(() => import('../../pages/school')),
    role: ["Admin", "SubAdmin"],
  },
  {
    exact: true,
    private: true,
    moduleName: "District",
    screenName: "add-districts",
    path: `${env.PUBLIC_URL}/add-districts`,
    component: lazy(() => import('../../pages/district')),
    role: ["Admin", "SubAdmin"],
  },
  {
    exact: true,
    private: true,
    moduleName: "Promo Code",
    screenName: "add-promo-codes",
    path: `${env.PUBLIC_URL}/add-promo-codes`,
    component: lazy(() => import('../../pages/promo-code')),
    role: ["Admin", "SubAdmin"],
  },
  {
    exact: true,
    private: true,
    moduleName: "Roles Permission",
    screenName: "roles-permission",
    path: `${env.PUBLIC_URL}/roles-permission`,
    component: lazy(() => import('../../pages/roles-permission')),
    role: ["Admin"],
  },
  {
    exact: true,
    private: true,
    moduleName: "Subscribed User",
    screenName: "subscribed-user",
    path: `${env.PUBLIC_URL}/subscribed-user`,
    component: lazy(() => import('../../pages/subscription')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName: "Tags",
    screenName: "add-tags",
    path: `${env.PUBLIC_URL}/add-tags`,
    component: lazy(() => import('../../pages/add-tags-listing')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    // moduleName: "Manage Teacher",
    screenName: "manage-teachers",
    moduleName:"User Management",
    // screenName: "user-management",
    path: `${env.PUBLIC_URL}/manage-teachers`,
    component: lazy(() => import('../../pages/manage-teachers')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName: "Manage Schools",
    screenName: "manage-schools",
    path: `${env.PUBLIC_URL}/manage-schools`,
    component: lazy(() => import('../../pages/manage-schools')),
    role: ["Admin", "SubAdmin",],
  },
  
  {
    exact: true,
    private: true,
    moduleName: "Student List",
    screenName: "student-list",
    path: `${env.PUBLIC_URL}/student-list`,
    component: lazy(() => import('../../pages/student-list')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName: "School Manage Teachers",
    screenName: "school-manage-teachers",
    path: `${env.PUBLIC_URL}/school-manage-teachers`,
    component: lazy(() => import('../../pages/school-manage-teachers')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName: "Upload Excel",
    screenName: "upload-excel",
    path: `${env.PUBLIC_URL}/upload-excel`,
    component: lazy(() => import('../../pages/upload-excel')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName: "Subscription Manage",
    screenName: "subscription-manage",
    path: `${env.PUBLIC_URL}/subscription-manage`,
    component: lazy(() => import('../../pages/subscription-manage')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName: "School Dashboard",
    screenName: "school-dashboard",
    path: `${env.PUBLIC_URL}/school-dashboard`,
    component: lazy(() => import('../../pages/school-dashboard')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName:"User Management",
    screenName: "user-management",
    path: `${env.PUBLIC_URL}/user-management`,
    component: lazy(() => import('../../pages/user-management')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName:"User Management",
    screenName: "user-management",
    path: `${env.PUBLIC_URL}/nonverify-user`,
    component: lazy(() => import('../../pages/nonverify-user')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName: "Grades",
    screenName: "add-grades",
    path: `${env.PUBLIC_URL}/add-grades`,
    component: lazy(() => import('../../pages/add-grade')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true,
    moduleName: "",
    screenName: "",
    path: `${env.PUBLIC_URL}/add-category`,
    component: lazy(() => import('../../pages/add-category')),
    role: ["Admin", "SubAdmin",],
  },
  
  
  {
    exact: true,
    private: true,
    moduleName: "My Schools",
    screenName: "my-schools",
    path: `${env.PUBLIC_URL}/my-schools`,
    component: lazy(() => import('../../pages/my-schools')),
    role: ["Admin", "District"],
  },
  {
    exact: true,
    private: true,
    moduleName: "Alee Coach",
    screenName: "alee-coachs",
    path: `${env.PUBLIC_URL}/alee-coachs`,
    component: lazy(() => import('../../pages/alee-coach-create')),
    role: ["Admin", "SubAdmin",],
  },
  {
    exact: true,
    private: true, 
    moduleName: "District",
    screenName: "add-districts-detail",
    path: `${env.PUBLIC_URL}/add-districts-detail`,
    component: lazy(() => import('../../pages/add-districtDetail')),
    role: ["Admin", "SubAdmin"],
  },
  
  {
    exact: true,
    private: true,
    moduleName: "Subscription Plan",
    screenName: "subscription-plan",
    path: `${env.PUBLIC_URL}/subscription-plan`,
    component: lazy(() => import('../../pages/subscription-plan')),
    role: ["Admin", "SubAdmin",],
  },
  
  //  common routes for "Teacher", "School", "District"
  
  {
    exact: true,
    private: true,
    moduleName: "Teacher Library",
    screenName: "teacher-library",
    path: `${env.PUBLIC_URL}/teacher-library`,
    component: lazy(() => import('../../pages/teacher-library')),
    role: ["Teacher", "School", "District"],
  },
  {
    exact: true,
    private: true,
    moduleName: "Book Detail",
    screenName: "book-detail",
    path: `${env.PUBLIC_URL}/book-detail/:bookId`,
    component: lazy(() => import('../../pages/book-detail')),
    role: ["Teacher", "School", "District",],
  },
  {
    exact: true,
    private: true,
    moduleName: "Alee Coach",
    screenName: "alee-coach",
    path: `${env.PUBLIC_URL}/alee-coach`,
    component: lazy(() => import('../../pages/alee-coach')),
    role: ["Teacher", "School", "District",],
  },
  {
    exact: true,
    private: true,
    moduleName: "Alee Coach Summary",
    screenName: "alee-coach-summary",
    path: `${env.PUBLIC_URL}/alee-coach-summary`,
    component: lazy(() => import('../../pages/alee-coach-summary')),
    role: ["Teacher", "School", "District",],
  },
  {
    exact: true,
    private: true,
    moduleName: "Teacher Lesson Plan",
    screenName: "teacher-Lesson-plan",
    path: `${env.PUBLIC_URL}/teacher-Lesson-plan/:bookId`,
    component: lazy(() => import('../../pages/teacher-Lesson-plan')),
    role: ["Teacher", "School", "District"],
  },
  {
    exact: true,
    private: true,
    moduleName: "Teacher All Lesson",
    screenName: "teacher-all-lesson",
    path: `${env.PUBLIC_URL}/teacher-all-lesson`,
    component: lazy(() => import('../../pages/add-viewall')),
    role: ["Teacher", "School", "District"],
  },
  {
    exact: true,
    private: true,
    moduleName: "Teacher Lesson",
    screenName: "teacher-lesson",
    path: `${env.PUBLIC_URL}/teacher-lesson`,
    component: lazy(() => import('../../pages/teacher-Lesson')),
    role: ["Teacher", "School", "District"],
  },
  
  //******************************************************************************************************************//
  //*************/ Teacher  based routeConfig**************//
  
  {
    exact: true,
    private: true,
    moduleName: "Lesson Plan 2",
    screenName: "lesson-plan-2",
    path: `${env.PUBLIC_URL}/lesson-plan-2`,
    component: lazy(() => import('../../pages/lesson-plan-2')),
    role: ["Teacher",],
  },
  
  {
    exact: true,
    private: true,
    moduleName: "Teacher Student",
    screenName: "teacher-student",
    path: `${env.PUBLIC_URL}/teacher-student`,
    component: lazy(() => import('../../pages/teacher-Student')),
    role: ["Teacher",],
  },
  
  
  
  {
    exact: true,
    private: true,
    moduleName: "My Library",
    screenName: "my-library",
    path: `${env.PUBLIC_URL}/my-library`,
    component: lazy(() => import('../../pages/my-library')),
    role: ["Teacher"],
  },
  {
    exact: true,
    private: true,
    moduleName: "Curated For You",
    screenName: "curatedForYou",
    path: `${env.PUBLIC_URL}/curatedForYou`,
    component: lazy(() => import('../../pages/curatedForYou')),
    role: ["Teacher"],
  },
  
  {
    exact: true,
    private: true,
    moduleName: "Create Profile",
    screenName: "create-profile",
    path: `${env.PUBLIC_URL}/create-profile`,
    component: lazy(() => import('../../pages/create-profile')),
    role: ["Teacher"],
  },
  {
    exact: true,
    private: true,
    moduleName: "Create Advanced Profile",
    screenName: "create-advanced-profile",
    path: `${env.PUBLIC_URL}/create-advanced-profile`,
    component: lazy(() => import('../../pages/create-advanced-profile')),
    role: ["Teacher"],
  },
  {
    exact: true,
    private: true,
    moduleName: "Start Teaching",
    screenName: "start-teaching",
    path: `${env.PUBLIC_URL}/start-teaching`,
    component: lazy(() => import('../../pages/start-teaching')),
    role: ["Teacher"],
  },
  // {
  //   exact: true,
  //   private: true,
  //   moduleName: "Teacher Dashboard",
  //   screenName: "teacher-dashboard",
  //   path: `${env.PUBLIC_URL}/teacher-dashboard`,
  //   component: lazy(() => import('../../pages/teacher-dashboard')),
  //   role: ["Teacher"],
  // },
  
  
  // school routes
  {
    exact: true,
    private: true,
    moduleName: "School Dashboard",
    screenName: "school-dashboard",
    path: `${env.PUBLIC_URL}/school-dashboard`,
    component: lazy(() => import('../../pages/school-dashboard')),
    role: ["School",],
  },
  {
    exact: true,
    private: true,
    moduleName: "School Teacher",
    screenName: "start-teaching",
    path: `${env.PUBLIC_URL}/school-teacher`,
    component: lazy(() => import('../../pages/school-teacher')),
    role: ["School",],
  },
  {
    exact: true,
    private: true,
    moduleName: "My Teachers",
    screenName: "my-teachers",
    path: `${env.PUBLIC_URL}/my-teachers`,
    component: lazy(() => import('../../pages/my-teachers'  )),
    role: ["School"],
  },
  {
    exact: true,
    private: true,
    moduleName: "School Profile",
    screenName: "school-profile",
    path: `${env.PUBLIC_URL}/school-profile`,
    component: lazy(() => import('../../pages/school-profile')),
    role: ["School",],
  },
  
  
  // ******************************** District routes ****************************
  {
    exact: true,
    private: true,
    moduleName: "School Details",
    screenName: "school-details",
    path: `${env.PUBLIC_URL}/school-details`,
    component: lazy(() => import('../../pages/school-details')),
    role: ["District",],
  },
  
  {
    exact: true,
    private: true,
    moduleName: "District Profile",
    screenName: "district-profile",
    path: `${env.PUBLIC_URL}/district-profile`,
    component: lazy(() => import('../../pages/district-profile')),
    role: ["District",],
  },
  {
    exact: true,
    private: true,
    moduleName: "District Dashboard",
    screenName: "district-dashboard",
    path: `${env.PUBLIC_URL}/district-dashboard`,
    component: lazy(() => import('../../pages/district-dashboard')),
    role: ["District",],
  },
  {
    exact: true,
    private: true,
    moduleName: "User Management",
    screenName: "messages",
    path: `${env.PUBLIC_URL}/messages`,
    component: lazy(() => import('../../pages/messages')),
    role: ["Admin","SubAdmin"],
  },  
];

export default AuthenticateTemplateRoutesConfing;